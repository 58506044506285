import { createStore, applyMiddleware, compose } from "redux"
import createDebounce from "redux-debounced"
import thunk from "redux-thunk"
import { persistReducer, persistStore } from 'redux-persist';
import rootReducer from "../reducers/rootReducer"
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

const middlewares = [thunk, createDebounce()];

const persistConfig = {
  key: 'root',
  storage,
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Redux persist
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  {},
  composeEnhancers(applyMiddleware(...middlewares))
)

const persistor = persistStore(store);

export { store, persistor };
