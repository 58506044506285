const initialState = {
  details: {}
};

export const login = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_WITH_EMAIL": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_FB": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_TWITTER": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_GOOGLE": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_GITHUB": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_JWT": {
      return { ...state, values: action.payload }
    }
    case "LOGOUT_WITH_JWT": {
      return { ...state, values: action.payload }
    }
    case "LOGOUT_WITH_FIREBASE": {
      return { ...state, values: action.payload }
    }
    case "CHANGE_ROLE": {
      return { ...state, userRole: action.userRole }
    }
    case "USER_DETAILS": {
      return { ...state, details: action.userDetails }
    }
    case "UPDATE_PAYMENT": {
      const data = Object.assign({}, state);
      return { ...state, details: { ...state.details, status: action.status, device_type: 3, expired_date: action.expiredDate } }
    }
    case "FLUSH": {
      return { ...initialState }
    }
    default: {
      return state
    }
  }
}
