import React from "react";
import { connect } from 'react-redux';
import { Navbar } from "reactstrap"
import classnames from "classnames"
import NavbarBookmarks from "./NavbarBookmarks"
import { history } from "../../../history";

const ThemeNavbar = props => {
  const colorsArr = [ "primary", "danger", "success", "info", "warning", "dark", '#000000'];
  const navbarTypes = ["floating" , "static" , "sticky" , "hidden"];

  const { premiumStatus } = props;
  const status = premiumStatus && premiumStatus.details && premiumStatus.details.status;

  return (
    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames(
          "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
          {
            "navbar-light": props.navbarColor === "default" || !colorsArr.includes(props.navbarColor),
            "navbar-dark": colorsArr.includes(props.navbarColor),
            "bg-primary":
              props.navbarColor === "primary" && props.navbarType !== "static",
            "bg-danger":
              props.navbarColor === "danger" && props.navbarType !== "static",
            "bg-success":
              props.navbarColor === "success" && props.navbarType !== "static",
            "bg-info":
              props.navbarColor === "info" && props.navbarType !== "static",
            "bg-warning":
              props.navbarColor === "warning" && props.navbarType !== "static",
            "bg-dark":
              props.navbarColor === "dark" && props.navbarType !== "static",
            "d-none": props.navbarType === "hidden" && !props.horizontal,
            "floating-nav":
              (props.navbarType === "floating" && !props.horizontal) || (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            "navbar-static-top":
              props.navbarType === "static" && !props.horizontal,
            "fixed-top": props.navbarType === "sticky" || props.horizontal,
            "scrolling": props.horizontal && props.scrolling

          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-center align-items-center"
              id="navbar-mobile"
            >
              <div className="bookmark-wrapper">
                <NavbarBookmarks
                  sidebarVisibility={props.sidebarVisibility}
                  handleAppOverlay={props.handleAppOverlay}
                />
              </div>
              {status !== 1 && (
                <p className="text-white font-open-sans font-size-12 mb-0 cursor-pointer" onClick={() => history.push("/plan")}>Upgrade to Premium for only €9,99 a month</p>
              )}
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  )
}

function mapStateToProps(state) {
  return {
    premiumStatus: state.auth.login,
  }
}

export default connect(mapStateToProps)(ThemeNavbar);
