 import React from "react"
 import * as Icon from "react-feather"

const horizontalMenuConfig = [
  {
    id: "home",
    title: "Home",
    type: "item",
    permissions: ["admin", "editor"],
    navLink: "/"
  },
  // {
  //   id: "minute-workouts",
  //   title: "10 Minute Workouts",
  //   type: "item",
  //   permissions: ["admin", "editor"],
  //   navLink: "/"
  // },
  // {
  //   id: "programs",
  //   title: "Programs",
  //   type: "item",
  //   permissions: ["admin", "editor"],
  //   navLink: "/"
  // },
  // {
  //   id: "groups",
  //   title: "Groups",
  //   type: "item",
  //   permissions: ["admin", "editor"],
  // },
  // {
  //   id: "weekly-schedule",
  //   title: "Weekly Schedule",
  //   type: "item",
  //   permissions: ["admin", "editor"],
  // },
  // {
  //   id: "yolanthe-live",
  //   title: "Yolanthe Live",
  //   type: "item",
  //   permissions: ["admin", "editor"],
  //   navLink: "/"
  // },
  // {
  //   id: "shop",
  //   title: "Shop",
  //   type: "item",
  //   permissions: ["admin", "editor"],
  // },
  {
    id: "profile",
    type: "item",
    icon: <Icon.User size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/profile"
  } 
]

export default horizontalMenuConfig
