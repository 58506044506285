import React from "react";
// import ScrollToTop from "react-scroll-up"
// import { Button } from "reactstrap"
// import { Heart, ArrowUp } from "react-feather"
// import classnames from "classnames"
import { Link } from "react-router-dom"
import Cabaulogo from "../../../assets/img/icons/cabau-logo.svg";
import { Row, Col, NavLink } from "reactstrap";
const Footer = () => {

  const handelUrlTc = () => {
    const url = "https://cabaulifestyle.com/policies/terms-of-service";
    window.open(url, "_blank");
  };
  const handelUrlPp = () => {
    const url = "https://cabaulifestyle.com/policies/privacy-policy";
    window.open(url, "_blank");
  };
  return (
    <React.Fragment>
      <section className="footer-sec bg-linear-gradient-pink">
        <Row className="mx-0">
          <Col sm="12" xl="4" lg="4" md="12" className="d-flex align-items-center">
            <div className="footer-logo d-flex w-100 mrg-sm-1">
              <NavLink to="/"><img src={Cabaulogo} alt="logo" /></NavLink>
            </div>
          </Col>
          <Col sm="12" xl="8" lg="8" md="12">
            <div className="footer-menu d-flex justify-content-end">
              <ul className="d-flex pl-0 mb-0 align-items-center">
                <li className="mrg-sm-1">
                <Link  className="color-white font-family-orbitron  mx-1">
                    Terms of use
                  </Link>
                </li>
                <li className="mrg-sm-1">
                <Link onClick={(e) => handelUrlTc(e)} className="color-white font-family-orbitron mx-1">
                    Privacy Policy
                  </Link>
                </li>
                <li className="mrg-sm-1">
                <Link onClick={(e) => handelUrlPp(e)} className="color-white font-family-orbitron mx-1">
                    Terms and Conditions
                  </Link>
                </li>
                <li className="mrg-sm-1">
                  <Link to="/" className="color-white font-family-orbitron mx-1">
                    Mobile app
                  </Link>
                </li>
                <button className="rounded-circle color-pink border-0 font-open-sans wh-33 mr-2">?</button>
              </ul>
            </div>
          </Col>
        </Row>
      </section>
    </React.Fragment>
  );
};

export default Footer;
