import React, { Suspense, lazy } from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import { Layout } from "./utility/context/Layout"
import * as serviceWorker from "./serviceWorker"
import { store, persistor } from "./redux/storeConfig/store"
import { IntlProviderWrapper } from "./utility/context/Internationalization"
import Spinner from "./components/@vuexy/spinner/Fallback-spinner"
import "./index.scss"
// import "./@fake-db"
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from 'react-toastify';

const LazyApp = lazy(() => import("./App"))

// configureDatabase()
ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      <Suspense fallback={<Spinner />}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
        <Layout>
          <IntlProviderWrapper>
            <LazyApp />
          </IntlProviderWrapper>
        </Layout>
        <ToastContainer/>
      </Suspense>
      </PersistGate>
    </Provider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
