import React from "react"
import themeConfig from "../configs/themeConfig"
import classnames from "classnames"

// import { Container }  from "reactstrap"

const FullPageLayout = ({ children, ...rest }) => {
  return (
    <div>
      <div
        className={classnames(
          "blank-page dark-layout",
          {
            "layout-dark": themeConfig.layoutDark
          }
        )}
      >
        <main className="main w-100">{children}</main>
      </div>
    </div>
  )
}

export default FullPageLayout
